<!-- AI测评页面 -->
<template>
  <div class="aitest">
    <div class="aiinfo flex-align-between">
      <div class="time">
        <div class="top flex-align-center">
          <div class="flex-align">
            <img :src="require('../../assets/task/pen.png')">
            AI测评次数
          </div>
        </div>
        <div class="bottom">{{testInfo.testCount}}<span>次</span></div>
      </div>
      <div class="level">
        <div class="top flex-align-center">
          <div class="flex-align">
            <img :src="require('../../assets/task/cup-white.png')">
            AI测评水平
          </div>
        </div>
        <div class="bottom flex-align-center" v-if="list.length>0">
          {{testInfo.testLevel==5?'优秀':testInfo.testLevel==3?'良好':testInfo.testLevel==4?'良好':'待提升'}}
        </div>
        <div class="bottom flex-align-center" v-else>
          暂无
        </div>
      </div>
    </div>
    <div class="ailog flex-align">
      <img :src="require('../../assets/task/log.png')">
      AI测评记录
    </div>
    <!-- <van-list v-model="loading" v-if="list.length>0" :finished="finished" finished-text="没有更多了" @load="onload"> -->
    <div class="logwrap flex-align-between">
        <div class="logitem" v-for="(item,index) in list" :key='index'>
          <img v-if="item.level==5" class="badge" :src="require('../../assets/task/youxiu.png')" />
          <img v-if="item.level==3||item.level==4" class="badge" :src="require('../../assets/task/lianghao.png')" />
          <img v-if="item.level==2||item.level==1" class="badge" :src="require('../../assets/task/tisheng.png')" />
          <img class="works" :src="item.filePath+'&width=100'" @click="preview(item.filePath)" />
          <div class="info flex-align-between">
            <span>{{item.time}}</span>
            <div @click="goReport(item.id)">
              查看
              <img :src="require('../../assets/task/right.png')" alt="">
            </div>
          </div>
        </div>
      </div>
    <!-- </van-list> -->
    <!-- <div class="logwrap" v-else>
      <van-empty description="暂无记录" />
    </div> -->
  </div>
</template>
<script>
  //   import {
  //     ImagePreview
  //   } from 'vant';
  export default {
    data() {
      return {
        testInfo: {
          testCount: 0,
          testLevel: null
        },
        list: [],
        page: 1,
        limit: 10,
        loading: false,
        finished: false,
      }
    },
    methods: {
      // 预览图片
      preview(url) {
        console.log('图片预览--',url)
        // ImagePreview([url])
      },
      // 获取测评记录
      gettestlog() {
        let data = {
          name: this.$route.query.workName,
          role:0 //0 参与 1创建
        };
        this.$Api.Myclass.getQQTestDetail(data)
          .then(res => {
            console.log('获取成功', res);
            let score = 0;
            res.data.map(item => {
              score += item.score
            })
            console.log('成绩总和--', score);
            console.log('平均成绩', (score / res.data.length).toFixed());
            this.list = res.data;
            this.testInfo.testCount = res.data.length;
            this.testInfo.testLevel = (score / res.data.length).toFixed()
          })
          .catch(err => {
            console.log('获取记录失败', err)
          })
      },
      goReport(id) {
        let query = {
          id
        }
        this.$router.push({
          path:'/aireport',
          query
        })
      },

    },
    mounted() {
      this.gettestlog();
    }
  };
</script>
<style lang='less' scoped>
  @import url('./less/aitest.less');
</style>